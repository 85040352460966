define('viewmodels/mixins/popup',['require','knockout','plugins/router','services/state-container'],function(require) {
    var ko = require('knockout');
    var router = require('plugins/router');
    var state = require('services/state-container');
    
    var PopupMixin = function() {
        var self = this;
        
        self.showPopup = ko.observable(null);

        self.closePopup = function() {
            self.showPopup(null);
        }

        self.maybeShowPopup = function(section) {
            const isOnWelcomePage = router.activeItem().__moduleId__ === 'viewmodels/pages/dashboard-page';
            if (state.shellMode() !== 'sauna' && 
                state.shellMode() !== 'landing' && 
                state.shellMode() !== 'bike' && 
                state.shellMode() !== 'dormer' && 
                state.shellMode() !== 'grass' && 
                state.shellMode() !== 'airco' && 
                !isOnWelcomePage) return false;
            if (state.isLoggedIn()) return false;
            self.showPopup('popup popup-' + section);
            return true;
        }

        $('body').on('click', function() { 
            self.showPopup(null); 
        });
    };

    return PopupMixin;
});

define('viewmodels/pages/grass-landing-page',['require','knockout','lodash','plugins/router','durandal/app','plugins/dialog','toastr','plugins/http','utils/parsing','services/state-container','utils/string','services/customer','services/user','models/woop','utils/formatting','viewmodels/mixins/busy','viewmodels/components/panda','viewmodels/components/lead-form-data','viewmodels/components/benefits','text!settings.json','viewmodels/base'],function(require) {
    var ko = require('knockout');
    var _ = require('lodash');
    var router = require('plugins/router');
    var app = require('durandal/app');
    var dialog = require('plugins/dialog');
    var toastr = require('toastr');
    var http = require('plugins/http');
    var parsing = require('utils/parsing');
    var state = require('services/state-container');
    var stringUtils = require('utils/string');
    var customerService = require('services/customer');
    var userService = require('services/user');
    var Woop = require('models/woop');
    var formatting = require('utils/formatting');
    var BusyMixin = require('viewmodels/mixins/busy');
    var PandaViewModel = require('viewmodels/components/panda');
    var LeadFormDataViewModel = require('viewmodels/components/lead-form-data');
    var BenefitsViewModel = require('viewmodels/components/benefits');

    var settings = JSON.parse(require('text!settings.json'));
    var BaseViewModel = require('viewmodels/base');

    var GrassLandingPageViewModel = function() {

        var self = this;
        _.assign(self, new BusyMixin());
        self.isFront = false;
        self.isResponsive = true;
        self.opensDialog = false;
        self.checkedFields = ko.observable([]);
        self.benefits = new BenefitsViewModel();
        self.benefitsMobile = new BenefitsViewModel(true);

        self.currentPage = ko.observable(1);

        self.targetKeyword = ko.observable(null);
        self.emailAddress = ko.observable(state.isLoggedIn() ? state.user().emailAddress() : '');
        self.name = ko.observable(state.isLoggedIn() ? state.user().firstName() + ' ' + state.user().lastName() : '');
        self.phoneNumber = ko.observable(state.isLoggedIn() ? state.user().phoneNumber() : '');
        self.maxPrice = ko.observable('');
        self.keywords = ko.observable();

        const isMobile = window.matchMedia('(max-width: 800px)').matches;

        self.panda = new PandaViewModel(self.keywords, self.targetKeyword, true);
        self.formData = ko.observable(new LeadFormDataViewModel(null, isMobile, 'grass'));

        self.isShowingSignInDialog = ko.observable(false);
        self.isVisible = ko.pureComputed(function(){
            if (state.isLoggedIn()) return false;
            if (self.isShowingSignInDialog()) return false;
            return true;
        });

        self.go = function() {
            self.postNewWoopStep2(self.keywords());
        };

        self.confirmForm = function() {
            ga('send', 'event', 'Kunstgras landing', 'click', 'Kunstgras form step 1 completed');
            self.checkedFields(self.formData().export().filter(function(x) {
                return x.type === 'header' || x.value
            }));

            if (state.isLoggedIn()) {
                self.currentPage(2);
                self.benefits.showLinks(false);      
            }
            else {
                self.postNewWoopStep1(self.keywords());
            }

        };

        self.goBack = function() {
            self.currentPage(1);
            self.benefits.showLinks(true);
        };

        self.isGoButtonEnabled = ko.pureComputed(function() {
            if (stringUtils.isEmpty(self.emailAddress())) return false;
            if (stringUtils.isEmpty(self.name())) return false;
            if (stringUtils.isEmpty(self.phoneNumber())) return false;
            return true;
        });
        self.isConfirmButtonEnabled = ko.pureComputed(function() {
            if (stringUtils.isEmpty(self.emailAddress())) return false;
            return true;
        });

        self.postNewWoopStep1 = function(keywords) {
            var password = userService.generateRandomPassword();
            keywords = keywords || 'Kunstgras';
            ga('send', 'event', 'Kunstgras landing', 'click', 'Kunstgras form step 1 completed', keywords);

            userService.registerCustomer(
                self.emailAddress(), 
                password, password, 
                keywords, 
                self.targetKeyword(), 
                self.formData().serialized(), 
                formatting.priceValue(self.maxPrice()),
                function(err) {
                    if (err) {
                        return toastr.error(err);
                    }

                    if (self.phoneNumber() || self.name()) {
                        const profile = {
                            name: self.name(),
                            phone: self.phoneNumber()
                        };
                        customerService.updateProfile(profile, _.noop);
                    }

                    self.currentPage(2);
                    self.benefits.showLinks(false);                        
                }
            );
        };

        self.postNewWoopStep2 = function(keywords) {

            keywords = keywords || 'Kunstgras';

            ga('send', 'event', 'Kunstgras landing', 'click', 'Kunstgras form step 2 completed', keywords);

            self.keywords(keywords);
            state.woops([]);
            state.firstWoop.keywords(self.keywords());
            state.firstWoop.targetKeyword(self.targetKeyword());
            state.woops.push(Woop.createNew(self.keywords(), self.targetKeyword(), self.formData().serialized()));

            if (!stringUtils.isEmpty(self.keywords())) {
                var woop = { 
                    keywords: self.keywords(), 
                    targetKeyword: self.targetKeyword(), 
                    formData: self.formData().serialized(),
                    maximumPrice: formatting.priceValue(self.maxPrice()),
                };
                customerService.addWoop(woop, function(err, woopId) {
                    if (err) return toastr.error(err);
                    toastr.success('Nieuwe woop is geplaatst');

                    if (self.phoneNumber() || self.name()) {
                        const profile = {
                            name: self.name(),
                            phone: self.phoneNumber()
                        };
                        customerService.updateProfile(profile, _.noop);
                    }

                    state.shellMode('customer');
                    router.navigate('profile');
                    
                    dialog.getContext().blockoutOpacity = 0.66;
                    dialog.show('viewmodels/dialogs/landing-feedback-dialog', 'customer');

                });

                self.keywords(null);
            }
        }

        self.activate = function() {
            ga('send', 'event', 'Kunstgras landing', 'view', 'Kunstgras landing page viewed');
            state.shellMode('grass');

            // if (state.isLoggedIn()) {
            //     state.shellMode('customer');
            //     router.navigate('dashboard');   
            //     state.isSaunaSession(false);
            // }

            var context = router.activeInstruction();
            var params = context.params[1]
            if (params && params.src && params.access_token) {
                if (params.src === 'forgotpass') return self.forgotPassword(params.access_token);
            }
        };

    };

    GrassLandingPageViewModel.prototype = new BaseViewModel();
    return GrassLandingPageViewModel;
});


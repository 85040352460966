define('viewmodels/shell',['require','knockout','jquery','lodash','toastr','services/state-container','durandal/app','plugins/router','plugins/dialog','services/user','services/landing','text!settings.json','viewmodels/components/header','viewmodels/pages/welcome-page','viewmodels/pages/blocked-page','viewmodels/pages/dashboard-page','viewmodels/pages/sauna-landing-page','viewmodels/pages/bike-landing-page','viewmodels/pages/dormer-landing-page','viewmodels/pages/grass-landing-page','viewmodels/pages/airco-landing-page','viewmodels/pages/landing-page','viewmodels/pages/offers-page','viewmodels/components/dashboard-dialogs','viewmodels/base'],function(require) {
    var ko = require('knockout');
    var $ = require('jquery');
    var _ = require('lodash');
    var toastr = require('toastr');
    var state = require('services/state-container');
    var app = require('durandal/app');
    var router = require('plugins/router');
    var dialog = require('plugins/dialog');
    var userService = require('services/user');
    var landingService = require('services/landing');
    var settings = JSON.parse(require('text!settings.json'));
    var HeaderViewModel = require('viewmodels/components/header');
    var WelcomePageViewModel = require('viewmodels/pages/welcome-page');
    var BlockedPageViewModel = require('viewmodels/pages/blocked-page');
    var DashboardPageViewModel = require('viewmodels/pages/dashboard-page');
    var SaunaLandingPageViewModel = require('viewmodels/pages/sauna-landing-page');
    var BikeLandingPageViewModel = require('viewmodels/pages/bike-landing-page');
    var DormerLandingPageViewModel = require('viewmodels/pages/dormer-landing-page');
    var GrassLandingPageViewModel = require('viewmodels/pages/grass-landing-page');
    var AircoLandingPageViewModel = require('viewmodels/pages/airco-landing-page');
    var LandingPageViewModel = require('viewmodels/pages/landing-page');
    var OffersPageViewModel = require('viewmodels/pages/offers-page');
    var DashboardDialogsViewModel = require('viewmodels/components/dashboard-dialogs');
    var BaseViewModel = require('viewmodels/base');

    var ShellViewModel = function() {
        var self = this;

        var dialogsCallback = function() { alert('yup!'); };
        self.dialogs = new DashboardDialogsViewModel({callback : dialogsCallback});

        // Front page
        self.isFrontPage = ko.pureComputed(function() {
            if (!router.activeItem()) return false;
            return router.activeItem().isFront;
        });
        self.isGreyPage = ko.pureComputed(function() {
            if (!router.activeItem()) return false;
            return router.activeItem().isGrey;
        });
        self.desktopClass = ko.pureComputed(function() {
            if (self.isResponsive()) return 'page-container-back clearfix desktop-only';
            return 'page-container-back clearfix';
        });
        self.mobileClass = ko.pureComputed(function() {
            if (self.isResponsive()) return 'mobile-only';
            return 'mobile-hidden';
        });
        self.isResponsive = ko.pureComputed(function() {
            return router.activeItem().isResponsive;
        })


        // Login/logout
        self.isLoggedIn = state.isLoggedIn;
        self.username = ko.pureComputed(function() {
            return state.user().firstName();
        });
        self.logout = function() {
            userService.logout();
            toastr.info('Je bent nu uitgelogd');
            self.cancelModal();
            router.navigate('/');
        };

        // Loading indicator
        self.showSpinner = ko.observable(false);
        app.on('spinner:show', function() { self.showSpinner(true); });
        app.on('spinner:hide', function() { self.showSpinner(false); });

        self.signIn = function() {
            dialog.getContext().blockoutOpacity = 0.66;
            dialog.show('viewmodels/dialogs/sign-in-dialog');
        };

        // Header component
        self.header = new HeaderViewModel(router);
        self.cancelModal = self.header.cancelModal;
        self.toDashboard = self.header.toDashboard;
        self.toProfile = self.header.toProfile;

        self.showLoginLogoutLink = ko.pureComputed(function() {
            return state.shellMode() !== 'sauna' && 
                state.shellMode() !== 'bike' && 
                state.shellMode() !== 'dormer' && 
                state.shellMode() !== 'grass' && 
                state.shellMode() !== 'airco';
        });

        // Routes
        self.router = router;
        self.activate = function () {
            router.map([
                { route: 'airco',                 title: 'Airco zoeken',            moduleId: 'viewmodels/pages/airco-landing-page',   nav: true },
                { route: 'dakkapel',              title: 'Dakkapel zoeken',         moduleId: 'viewmodels/pages/dormer-landing-page',   nav: true },
                { route: 'kunstgras',             title: 'Kunstgras zoeken',        moduleId: 'viewmodels/pages/grass-landing-page',   nav: true },
                { route: 'sauna',                 title: 'Sauna zoeken',            moduleId: 'viewmodels/pages/sauna-landing-page',    nav: true },
                { route: 'elektrische-fiets',     title: 'Fiets zoeken',            moduleId: 'viewmodels/pages/bike-landing-page',    nav: true },
                { route: 'landing',               title: 'Welkom',                  moduleId: 'viewmodels/pages/landing-page',    nav: true },
                { route: 'search',                title: 'Zoeken',                  moduleId: 'viewmodels/pages/search-page',           nav: false },
                { route: '/',                     title: 'Welkom',                  moduleId: 'viewmodels/pages/welcome-page',          nav: true },
                // { route: '',                      title: 'Welkom',                  moduleId: 'viewmodels/pages/blocked-page',          nav: true },
                { route: 'dashboard',             title: 'Dashboard',               moduleId: 'viewmodels/pages/dashboard-page',        nav: true },
                { route: 'profile',               title: 'Profiel',                 moduleId: 'viewmodels/pages/profile-page',          nav: true },
                { route: 'edit-woop/:id',         title: 'Zoekopdracht verfijnen',  moduleId: 'viewmodels/pages/edit-woop-page',        nav: true },
                { route: 'offers(/:id)',          title: 'Aanbiedingen',            moduleId: 'viewmodels/pages/offers-page',           nav: true },
                { route: 'new-woop',              title: 'Nieuwe woop plaatsen',    moduleId: 'viewmodels/pages/new-woop-page',         nav: true },
                { route: 'patterns/:id/matches(/:matchId)', title: 'Matches',       moduleId: 'viewmodels/pages/matches-page',          nav: true },
                { route: 'patterns(/:id)',        title: 'Product',                 moduleId: 'viewmodels/pages/pattern-page',          nav: true },
                { route: 'about',                 title: 'Over ons',                moduleId: 'viewmodels/pages/about-page',            nav: true },
                { route: 'privacy',               title: 'Privacy statement',       moduleId: 'viewmodels/pages/privacy-page',          nav: true },
                { route: 'what-is-woopan',        title: 'Wat is woopan?',          moduleId: 'viewmodels/pages/what-is-woopan-page',   nav: true },
            ]).buildNavigationModel();

            router.mapUnknownRoutes(function(instruction){
                instruction = landingService.decideModule(instruction);
            });

            router.on('router:navigation:complete', function(route, x, y) {
                ga('send', 'pageview', router.activeInstruction().fragment);
            });

            router.guardRoute = function(routeInfo, params, instance) {
                console.log('ri', routeInfo, '**')
                if (routeInfo.isFront) return true;
                if (routeInfo instanceof BlockedPageViewModel) return true;
                if (routeInfo instanceof WelcomePageViewModel) return state.user().isUnregistered() ? true : 'dashboard';
                if (routeInfo instanceof BikeLandingPageViewModel) return true;
                if (routeInfo instanceof AircoLandingPageViewModel) return true;
                if (routeInfo instanceof GrassLandingPageViewModel) return true;
                if (routeInfo instanceof DormerLandingPageViewModel) return true;
                if (routeInfo instanceof SaunaLandingPageViewModel) return true;
                if (routeInfo instanceof LandingPageViewModel) return true;
                if (state.user().isUnregistered() && !(routeInfo instanceof WelcomePageViewModel)) return 'welcome';
                if (routeInfo === OffersPageViewModel && !state.isLoggedIn()) {
                    state.loginReferrer(params.fragment);
                    return '/?login';
                }
                if (routeInfo instanceof DashboardPageViewModel) return true;
                if (state.isLoggedIn()) return true;
                return '/';
            };

            router.on('router:navigation:complete', function() {
                userService.keepAlive();
                // what are the conditions for showing help?
                if (false) {
                    self.showHelp();
                }
            });

            return router.activate();
        };

        // show help dialog based on active page
        self.showingHelp = ko.observable(false);
        self.helpPng = ko.observable('');
        self.closeHelp = function() { self.showingHelp(false); };
        self.showHelp = function() {
            self.showingHelp(true);
            var moduleId = self.router.activeInstruction().config.moduleId;
            var n = moduleId.lastIndexOf('/');
            var pageId = moduleId.substring(n + 1);
            self.helpPng('img/help-overlays/'+pageId+'.png');
        };
    };

    ShellViewModel.prototype = new BaseViewModel();
    return new ShellViewModel();
});


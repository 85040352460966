define('models/grass-form',['require'],function(require) {

    var currentSection = null;

    const header = function(text) {
        currentSection = text;
        return { type: 'header', text: text };
    };

    const check = function(desc, info) {
        var res = { type: 'check', desc: desc, key: currentSection + '--' + desc, info: info || '' };
        return res;
    };

    return [
        [
            header('Toepassing'),
            check('Sportveld'),
            check('Tuin'),
            check('Speelplaats'),
            check('Dakterras/balkon'),
            check('Bedrijfsterreinen'),
            check('Anders'),

            header('Bestaande ondergrond'),
            check('Gras'),
            check('Zand'),
            check('Beton'),
            check('Grond'),
            check('Grind'),
            check('Houten vlonders of terrasplanken'),
            check('Tegels'),
            check('Zachte ondergrond'),
            check('Dakterras of daktuinen'),
            check('Anders'),

            header('Doel van het kunstgras'),
            check('Versiering'),
            check('Onderhoudsarm'),
            check('Intensief gebruik'),
            check('Praktisch'),
            
            header('Wanneer?'),
            check('Z.s.m.'),
            check('Binnen 2-3 maanden'),
            check('Binnen 6 maanden'),
            check('Later dan 6 maanden'),

            header('Soort kunstgras'),
            check('Luxe kuntgras (natuurlijke uitstraling)'),
            check('Dichte Vezels'),
            check('Kunstgras met een zanderige uitstraling'),
            check('Sportspecifiek kunstgras '),
            check('Infill'),
            check('Veiligheidsgrass (extra demping)'),
            check('Compact en lichtgewicht'),
            check('Adviseer mij'),

            header('Kleur'),
            check('Groen'),
            check('Donkergroen'),
            check('Lichtgroen'),
            check('Geelgroen'),
            check('Mengkleur tussen donker en lichtgroen'),
            check('Geel'),
            check('Bruin'),
            check('Blauw'),
            check('Anders'),

            header('Vezelhoogte'),
            check('Kort 15-29 mm'),
            check('Middellang 30-44 mm'),
            check('Lang 45-60 mm'),
            check('> 60'),
            check('Adviseer mij'),

            header('Stoffen kunstgras'),
            check('Polyethyleen'),
            check('Polypropyleen (PP)'),
            check('Nylon (PA)'),
            check('Polyurethaan (PU)'),
            check('Thermoplastisch Elastomeer (TPE)'),
            check('Polyamide (PA)'),
            check('Gerecycled PE of TPE'),
            check('adviseer mij'),
            
            header('Extra opties'),
            check('Drainagesysteem (voor afvoer water)'),
            check('Veerachtige onderlaag'),
            check('UV-bestendig'),
            check('Laag in onderhoud'),
            check('Anti slip'),

            header('Infill (vulling)'),
            check('Rubber'),
            check('zand '),
            check('TPE'),
            check('Kurk'),
            check('Zeoliet'),
            check('Adviseer mij'),

        ],
        [
            header('Kunstgras leggen'),
            check('Zelf'),
            check('Leg service'),

            header('Rolbreedte'),
            check('1m'),
            check('2m'),
            check('3m'),
            check('4m'),
            check('5m+'),
            check('Adviseer mij'),

            header('Steekdichtheid per m2'),
            check('5.000-10.000 (laag)'),
            check('10.000-18.000 (gemiddeld)'),
            check('18.000-25.000 (hoog)'),
            check('25.000-35.000 (zeer hoog)'),
            check('40.000+ (ultra hoog)'),
            check('Adviseer mij'),

            header('Wat voor woning heeft u'),
            check('Rijtjes huis'),
            check('Twee onder een kap'),
            check('Vrijstaand huis'),
            check('Appartement'),
            check('Penthouse'),
            check('Stadswoning'),
            check('Bedrijf'),
            check('Instelling'),
            check('Anders'),

            header('Vezeltype'),
            check('Monofilament (sterk en duurzaam)'),
            check('Multifilament (zachter en flexibeler)'),
            check('Worm- of S-vormige  (extra volume en sterkte)'),
            check('C-vormige vezels (extra sterkte en veerkracht)'),
            check('U-vormige vezels (extra stabiliteit)'),
            check('Fluwelen vezels (comfortabele ervaring)'),
            check('Turf-vezels  (Tuinen, decoratieve doeleinden)'),
            check('Anders'),
            check('Adviseer mij'),

            header('Denier (dikte van vezels in kunstgras)'),
            check('10000 denier (zeer dun)'),
            check('12.000 denier (dun)'),
            check('15.000 denier (gemiddeld)'),
            check('18.000 denier (dikkere vezels)'),
            check('20000 (zeer robuust)'),
            check('25000 (zeer dikke vezels) '),

            header('Zijn er rondingen'),
            check('Ja'),
            check('Nee'),

            header('Voor of achtertuin'),
            check('Voortuin'),
            check('Achtertuin'),
            check('Anders '),

            header('Grondwerk nodig'),
            check('Ja'),
            check('Nee'),
            check('Adviseer mij'),

            header('Heeft u kinderen of huisdieren'),
            check('Ja'),
            check('Nee '),

            header('Kantopsluiting'),
            check('Kunststof kant opsluiting'),
            check('Betonnen kant opsluiting '),
            check('Stalen kant opsluiting'),
            check('Alluminium kant opsluiting'),
            check('Houten kant opsluiting '),

            header('Toegang voor machines '),
            check('Ja '),
            check('Nee '),
            check('Weet ik niet'),
        ]
    ];
});
